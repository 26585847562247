import storage from 'util/storage'
import { oauthTokenSelector } from 'selectors/app/base'
import {
  doCreateMailbox,
  doDestroyMailbox,
  doFetchMailbox,
} from 'actions/mailboxes'

import { doUpdateMailbox, doDeleteMailbox } from 'ducks/mailboxes/actions'
import {
  doCreateFacebookWidget,
  doCreateInstagramWidget,
  doDeleteWidget,
  doUpdateWidget,
  doFetchWidgetById,
} from 'ducks/widgets/operations'

import {
  doBuildMenuFromWidgets,
  doBuildInboxMenuFromMailboxes,
} from 'ducks/folders/operations/collections'
import OAuthWindow from 'util/oauth_window'
import { fetchOAuthAuthenticationUrl } from 'subapps/onboarding/api/channels'
import { SETTINGS_WIDGET_CREATE_NEW_PAGE } from 'subapps/settings/types'
import { changeEntity, syncEntity } from 'ducks/entities/actionUtils'
import { pick } from 'util/objects'
import { convertHexToRGBA } from 'util/colors'
import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'
import { selectMailboxFolderSearchPath } from 'selectors/search/search'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { widgetsQuery } from 'ducks/widgets/api'
import { selectWidgetsWithoutChat } from 'ducks/widgets/selectors'
import { FETCH_WIDGETS } from 'ducks/widgets/types'
import { channel } from 'ducks/entities/schema'
import { selectCanConvertDemoMailboxToNewMailbox } from 'selectors/mailboxes'
import { selectDemoMailboxId } from 'selectors/mailboxes/selectDemoMailboxId'
import { doFetchAccountPreference } from 'ducks/accountPreferences/operations'
import {
  SAVE_CHANNEL_DRAFT,
  CLEAR_CHANNEL_DRAFT,
  SYNC_CLEAR_CHANNEL_DRAFT,
  SYNC_CHANNEL_DRAFT,
} from './actionTypes'
import { EMAIL_CHANNEL_TYPES, isBridgeChannelType } from './channelTypes'

const OAUTH_DEFAULT_MAILBOX_NAMES = {
  GOOGLE: 'Gmail',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
}

const MAILBOX_INPUT_FIELDS = [
  'channel_type',
  'email',
  'name',
  'color',
  'user_ids',
  'group_ids',
  'restriction_type',
  'signature',
  'outgoing_email_server',
  'incoming_email_server',
  'email_templates',
  'use_agent_name',
  'auto_bcc_address',
  'position',
  'ai_drafts_mailbox_ids',
  'ai_drafts_knowledge_base_ids',
]

export function doOauthChannel(
  mailboxId,
  { oAuthWindowOptions = { width: 600, height: 800 }, channelType } = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow(oAuthWindowOptions)
    return oAuthFlow
      .start(async () => {
        try {
          const res = await fetchOAuthAuthenticationUrl(token, channelType, {
            returnTo,
            mailboxId,
          })
          oAuthFlow.redirect(res.url)
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(() => {
        return dispatch(doFetchMailbox(mailboxId))
      })
      .catch(err => {
        oAuthFlow.cancel()
        throw err
      })
  }
}

export function doOauthGmailChannel(mailboxId, { oAuthWindowOptions } = {}) {
  return doOauthChannel(mailboxId, {
    oAuthWindowOptions,
    channelType: EMAIL_CHANNEL_TYPES.google,
  })
}

export function doOauthOutlookChannel(mailboxId, { oAuthWindowOptions } = {}) {
  return doOauthChannel(mailboxId, {
    oAuthWindowOptions,
    channelType: EMAIL_CHANNEL_TYPES.outlook,
  })
}

export function doOauthOffice365Channel(
  mailboxId,
  { oAuthWindowOptions } = {}
) {
  return doOauthChannel(mailboxId, {
    oAuthWindowOptions,
    channelType: EMAIL_CHANNEL_TYPES.office365,
  })
}

export function doAddMailboxBasedOauthChannel(
  type,
  channelFields,
  oAuthWindowOptions
) {
  return (dispatch, getState) => {
    const state = getState()
    let mailboxId
    const demoMailboxId = selectDemoMailboxId(state)
    const convertDemoChannelToNewChannel = selectCanConvertDemoMailboxToNewMailbox(
      state
    )

    const isFromDemoChannel = convertDemoChannelToNewChannel

    const mailboxName = OAUTH_DEFAULT_MAILBOX_NAMES[type]
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow(oAuthWindowOptions)
    return oAuthFlow
      .start(async () => {
        try {
          const data = pick(MAILBOX_INPUT_FIELDS, {
            name: mailboxName,
            ...channelFields,
            channel_type: type,
          })
          const oauthTest = storage.get('testOAuthWindowOverride') || {}

          if (convertDemoChannelToNewChannel) {
            const newData = { ...data, id: demoMailboxId }
            await dispatch(doUpdateMailbox(demoMailboxId, newData))
            mailboxId = demoMailboxId
          } else {
            const { id } = await dispatch(doCreateMailbox(data))
            mailboxId = id
          }
          if (!oauthTest.skipEmailOauth) {
            const res = await fetchOAuthAuthenticationUrl(token, type, {
              returnTo,
              mailboxId,
            })
            oAuthFlow.redirect(res.url)
          } else {
            // eslint-disable-next-line no-underscore-dangle
            oAuthFlow._succeed()
          }
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(async () => {
        if (convertDemoChannelToNewChannel) {
          await dispatch(doFetchAccountPreference('usage_onboarding'))
        }
        return dispatch(doFetchMailbox(mailboxId))
      })
      .catch(err => {
        if (mailboxId && !isFromDemoChannel) {
          // TODO (jscheel): Dispatch ouath error that adds error to page and
          // also calls doDestroyMailbox instead?
          dispatch(doDestroyMailbox(mailboxId))
        }
        oAuthFlow.cancel()
        throw err
      })
  }
}

export function doAddGmailChannel(
  mailboxFields = {},
  options = { width: 600, height: 800 }
) {
  return doAddMailboxBasedOauthChannel(
    EMAIL_CHANNEL_TYPES.google,
    mailboxFields,
    options
  )
}

export function doAddMessengerChannel(channelFields) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow({ width: 600, height: 800 })
    let widget

    return oAuthFlow
      .start(async () => {
        try {
          widget = await dispatch(
            doCreateFacebookWidget(
              channelFields?.name,
              convertHexToRGBA(channelFields?.color)
            )
          )

          const res = await fetchOAuthAuthenticationUrl(token, 'MESSENGER', {
            returnTo,
            widgetId: widget.id,
          })
          oAuthFlow.redirect(res.url)
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(() => {
        dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
        return widget
      })
      .catch(err => {
        if (widget) {
          dispatch(doDeleteWidget(widget.id))
        }
        oAuthFlow.cancel()
        throw err
      })
  }
}

export function doReconnectMessengerChannel(widget) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow({ width: 600, height: 800 })

    return oAuthFlow
      .start(async () => {
        try {
          const res = await fetchOAuthAuthenticationUrl(token, 'MESSENGER', {
            returnTo,
            widgetId: widget.id,
          })
          oAuthFlow.redirect(res.url)
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(() => {
        return dispatch(doFetchWidgetById(widget.id))
      })
      .then(() => {
        return widget
      })
      .catch(err => {
        oAuthFlow.cancel()
        throw err
      })
  }
}

export function doAddInstagramChannel(channelFields) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow({ width: 600, height: 800 })
    let widget

    return oAuthFlow
      .start(async () => {
        try {
          widget = await dispatch(
            doCreateInstagramWidget(
              channelFields?.name,
              convertHexToRGBA(channelFields?.color)
            )
          )

          const res = await fetchOAuthAuthenticationUrl(token, 'INSTAGRAM', {
            returnTo,
            widgetId: widget.id,
          })
          oAuthFlow.redirect(res.url)
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(() => {
        dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
        return widget
      })
      .catch(err => {
        if (widget) {
          dispatch(doDeleteWidget(widget.id))
        }
        oAuthFlow.cancel()
        throw err
      })
  }
}

export function doReconnectInstagramChannel(widget) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow({ width: 600, height: 800 })

    return oAuthFlow
      .start(async () => {
        try {
          const res = await fetchOAuthAuthenticationUrl(token, 'INSTAGRAM', {
            returnTo,
            widgetId: widget.id,
          })
          oAuthFlow.redirect(res.url)
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          oAuthFlow._fail(error)
        }
      })
      .then(() => {
        return dispatch(doFetchWidgetById(widget.id))
      })
      .then(() => {
        return widget
      })
      .catch(err => {
        oAuthFlow.cancel()
        throw err
      })
  }
}

// eslint-disable-next-line no-unused-vars
export const doSaveChannelDraft = (id, fields) => {
  return dispatch => {
    return dispatch({
      type: SAVE_CHANNEL_DRAFT,
      ...changeEntity('channel', id, fields, 'update', 'pending'),
    })
  }
}

// eslint-disable-next-line no-unused-vars
export const doClearChannelDraft = (id, options = {}) => {
  return {
    type: CLEAR_CHANNEL_DRAFT,
    ...changeEntity('channel', id, {}, 'remove', 'pending'),
  }
}

// eslint-disable-next-line no-unused-vars
export const doSyncAndClearChannelDraft = (id, options = {}) => {
  return {
    type: SYNC_CLEAR_CHANNEL_DRAFT,
    ...syncEntity('channel', id, 'move', 'pending'),
  }
}

// eslint-disable-next-line no-unused-vars
export const doSyncChannelDraft = (id, options = {}) => {
  return {
    type: SYNC_CHANNEL_DRAFT,
    ...syncEntity('channel', id, 'merge', 'current'),
  }
}

export const doUpdateChannel = (id, fields, options = {}) => async dispatch => {
  let resource

  if (isBridgeChannelType(options.channelType)) {
    await dispatch(
      doUpdateWidget(id, {
        userIds: fields.user_ids,
        groupIds: fields.group_ids,
        name: fields.name,
        channelColor: fields.color,
      })
    )
  } else {
    const data = pick(MAILBOX_INPUT_FIELDS, fields)
    resource = await dispatch(doUpdateMailbox(id, data, options))
  }

  dispatch(doSyncAndClearChannelDraft(id))
  return resource
}

export const doCreateChannel = fields => async dispatch => {
  // TODO: Extend this function to also support updating widgets and other types of channels
  const data = pick(MAILBOX_INPUT_FIELDS, fields)
  const mailbox = await dispatch(doCreateMailbox(data))
  dispatch(doClearChannelDraft('new'))

  return mailbox
}

export const doOpenSearchPageWithMailboxAndFolder = (
  channelId,
  folderId,
  options = {}
) => (dispatch, getState) => {
  if (!channelId) return null
  const state = getState()
  const inboxLink = selectMailboxFolderSearchPath(state, channelId, folderId)

  return dispatch(
    doOpenSearchPage(
      decodeURIComponent(inboxLink.replace('/search/', '')),
      false,
      options
    )
  )
}

export const doTryCreateChannelAndLoadResource = (
  newChannelId,
  channelType,
  data,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  const demoMailboxId = selectDemoMailboxId(state)
  const convertDemoChannelToNewChannel = selectCanConvertDemoMailboxToNewMailbox(
    state
  )

  const channelId = convertDemoChannelToNewChannel
    ? demoMailboxId
    : newChannelId

  const { redirect = false, rebuild = false } = options || {}
  let resource = null
  let createdChannelId = null
  const isNewChannel = channelId === 'new'

  dispatch(doSaveChannelDraft(channelId, data))

  if (isBridgeChannelType(channelType)) {
    resource = await dispatch(doFetchWidgetById(channelId))
    createdChannelId = channelId
  } else {
    if (data && channelType === 'forwarding') {
      if (isNewChannel) {
        resource = await dispatch(doCreateChannel(data))
      } else if (convertDemoChannelToNewChannel) {
        const newData = { ...data, id: channelId }
        const response = await dispatch(
          doUpdateChannel(channelId, newData, options)
        )
        resource = response.mailbox
      }
    }
    createdChannelId = isNewChannel ? resource?.id : channelId
    if (createdChannelId && createdChannelId !== 'new') {
      resource = await dispatch(
        doFetchMailbox(createdChannelId, {
          skipLoaded: false,
        })
      )
    }

    if (resource) {
      if (rebuild) {
        dispatch(doBuildInboxMenuFromMailboxes())
        if (redirect) {
          dispatch(
            doOpenSearchPageWithMailboxAndFolder(
              resource.id,
              resource.folders ? resource.folders[0].id : null
            )
          )
        }
      }

      dispatch(doSaveChannelDraft(createdChannelId, resource))
    }
  }

  return {
    isNewChannel,
    resource,
    createdChannelId,
  }
}

export const doDeleteChannel = (id, options = {}) => dispatch => {
  return dispatch(doDeleteMailbox(id, options))
}

const LOAD_ALL_CHAT_CHANNELS_QUERYID =
  'entityType:channel pageSize:10000 type:chat'

const getWidgetsWithChat = widgets => {
  // FIXME: Hiding facebook widgets as in selectWidgets from showing in the table for settings
  return widgets.filter(
    item =>
      item?.publishedSettings?.['chat.enabled'] ||
      isBridgeChannelType(item.channelType)
  )
}

export const doFetchChatChannels = ({ skipLoaded } = {}) => (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_CHAT_CHANNELS_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_WIDGETS,
      widgetsQuery(),
      {},
      {
        transformResponse: res => {
          const { widgets } = res
          return {
            channels: getWidgetsWithChat(widgets.data),
            widgets: widgets.data,
          }
        },
        normalizationSchema: { channels: [channel] },
        searches: {
          queryId,
          cursor,
          extractPagination: ({ channels: data }) => {
            return {
              type: 'channel',
              nodes: data,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: data.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchChatChannelsInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_CHAT_CHANNELS_QUERYID,
  entityType: 'channel',
  doLoadAllFn: doFetchChatChannels,
})

export const doSelectChatChannel = () => (dispatch, getState) => {
  const hasWidgetsWithoutChat = selectWidgetsWithoutChat(getState()).length > 0
  if (!hasWidgetsWithoutChat) {
    dispatch({
      type: SETTINGS_WIDGET_CREATE_NEW_PAGE,
    })
  }
  return hasWidgetsWithoutChat
}
